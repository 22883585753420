import React, { useState } from 'react';
import { changeDateFormat } from '../../../Shared/Constant/Constant';
import { useDispatch, useSelector } from 'react-redux';
import {
  negotiateSelector,
  sellerNegotiationsList,
  updateNegotiate,
  userBaseNegotiationsList,
} from '../../../../store/feature/negotiate/negotiateSlice';
import { listingSelector } from '../../../../store/feature/listingSlice';
import { loading_icon } from '../../../Shared/Constant/Icons/AllIcons';
import moment from 'moment/moment';

const SellerNegotiationCards = ({ selectedItem, selectedUser }) => {
  const dispatch = useDispatch();

  const { listing_details } = useSelector(listingSelector);
  const { isFetching } = useSelector(negotiateSelector);

  //
  const [selected_status, setSelectedStatus] = useState(null);

  // updateStatus
  const updateStatus = (status) => {
    setSelectedStatus(status);
    dispatch(
      updateNegotiate({
        negotiation_id: selectedItem?.id,
        listing_id: listing_details.id,
        updated_data: {
          negotiation: {
            status: status,
          },
        },
      })
    ).then((res) => {
      setSelectedStatus(null);
      dispatch(
        userBaseNegotiationsList({
          listing_id: listing_details?.id,
          params: {
            user_id: selectedUser?.id,
            account_id: listing_details?.account?.id,
          },
        })
      );
    });
  };

  // updateExpiration
  function updateExpiration(expirationDate) {
    const expiration = moment(expirationDate);
    const remainingTime = expiration.fromNow();

    if (listing_details?.in_cart === true) {
      return 'Listing added in cart';
    } else if (
      expiration.isBefore(moment()) &&
      listing_details?.in_cart !== true
    ) {
      return 'Time has expired';
    } else {
      return `Expire ${remainingTime}.`;
    }
  }

  return (
    <div className="flex flex-col gap-4 min-h-[100px] px-4  ">
      {/* initial */}
      <div className="  self-end  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-end ">
        <p className="text lg font-bold text-primary">
          {selectedItem?.user?.first_name} made an offer
        </p>
        <h1 className="title-h1 leading-none">
          {selectedItem?.price?.formatted}
        </h1>
        <p className="description-caption">
          {changeDateFormat(selectedItem?.created_at, 'hh:mm DD-MM-YYYY')}
        </p>
      </div>

      {/* 1st */}
      <div className="  self-end text-end  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1.5 ">
        <div className="flex items-center justify-end gap-1">
          <p className="text lg font-bold text-primary">Offer </p>
          <p className="text-sm font-bold text-primary ">
            (
            {updateExpiration(
              changeDateFormat(selectedItem?.expiry, 'YYYY-MM-DD')
            )}
            )
          </p>
        </div>
        {
          <div className="grid grid-cols-2 gap-2">
            <button
              className={[
                '  btn-primary-fill gap-2 ',
                (selectedItem?.status !== 1 || selectedItem?.expired) &&
                  'cursor-not-allowed',
              ].join(' ')}
              onClick={() => updateStatus(2)}
              disabled={selectedItem?.status !== 1 || selectedItem?.expired}
            >
              Accept {isFetching && selected_status === 2 && loading_icon}
            </button>
            <button
              className={[
                '  btn-primary-outline ',
                (selectedItem?.status !== 1 || selectedItem?.expired) &&
                  'cursor-not-allowed',
              ].join(' ')}
              onClick={() => updateStatus(3)}
              disabled={selectedItem?.status !== 1 || selectedItem?.expired}
            >
              Decline {isFetching && selected_status === 3 && loading_icon}
            </button>
          </div>
        }
        <p className="description-caption">
          {changeDateFormat(selectedItem?.created_at, 'hh:mm DD-MM-YYYY')}
        </p>
      </div>

      {/* 2nd */}
      {selectedItem?.status === 2 && (
        <div className="  self-start  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-start ">
          <p className="text lg font-bold text-primary">Accepted offer</p>
          <h1 className="title-h1 leading-none">
            {selectedItem?.price?.formatted}
          </h1>
          <p className="description-caption">
            {changeDateFormat(selectedItem?.updated_at, 'hh:mm DD-MM-YYYY')}
          </p>
        </div>
      )}
      {/* 3rd */}
      {selectedItem?.status === 3 && (
        <div className="  self-start  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-start ">
          <p className="text lg font-bold text-primary"> Offer rejected</p>
          <h1 className="title-h1 leading-none">
            {selectedItem?.price?.formatted}
          </h1>
          <p className="description-caption">
            {changeDateFormat(selectedItem?.updated_at, 'hh:mm DD-MM-YYYY')}
          </p>
        </div>
      )}
      {/* 4th */}
      {selectedItem?.status === 4 && (
        <div className="  self-end  w-3/5 h-auto p-3   bg-primary_light flex flex-col gap-1 text-start ">
          <p className="text lg font-bold text-primary">
            {' '}
            Offer closed by buyer
          </p>
          <h1 className="title-h1 leading-none">
            {selectedItem?.price?.formatted}
          </h1>
          <p className="description-caption">
            {changeDateFormat(selectedItem?.updated_at, 'hh:mm DD-MM-YYYY')}
          </p>
        </div>
      )}
    </div>
  );
};

export default SellerNegotiationCards;
