import React, { useEffect, useState } from 'react';
import {
  changeDateFormat,
  getThumbnailImage,
} from '../../../Shared/Constant/Constant';
import SidePan from '../../../Shared/Modal/SidePan';
import {
  negotiateSelector,
  sellerNegotiationsList,
} from '../../../../store/feature/negotiate/negotiateSlice';
import { useDispatch, useSelector } from 'react-redux';
import AllIcons, {
  default_user_icon,
  user_icon,
} from '../../../Shared/Constant/Icons/AllIcons';
import TabPopupUI from '../../../Shared/PopUp/UI/TabPopupUI';
import SellerNegotiationCards from './SellerNegotiationCards';
import Cookies from 'js-cookie';
import { TYPE_CONSTANT } from '../../../../constant/Web_constant';
import {
  CardBoxDefaultStyles,
  CardMainDefaultStyles,
} from '../../../../constant/default_styles/ProductDetailsStyles';
import { get_dynamic_styles_with_default_value } from '../../../../constant/DynamicStylesWithDefaultValue';
import { Listing_text } from '../../../../constant/Translation/Listing';
import SellerNegotiateList from './SellerNegotiateList';

const SellerNegotiatePart = ({ account, listing_details, styles }) => {
  const dispatch = useDispatch();
  const { seller_negotiations_list, isFetching } =
    useSelector(negotiateSelector);

  //
  useEffect(() => {
    if (
      listing_details &&
      Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
      JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
        listing_details?.account_id
    ) {
      dispatch(
        sellerNegotiationsList({
          listing_id: listing_details?.id,
          params: {
            account_id: listing_details?.account?.id,
            grouped: true,
          },
        })
      );
    }
  }, [listing_details]);

  //
  const [sellerrNegotiateSidePan, setSellerNegotiateSidePan] = useState(false);

  //
  const handleSidepanOpen = (e) => {
    e.stopPropagation();
    if (
      Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`) &&
      JSON.parse(Cookies.get(`${TYPE_CONSTANT.DOMAIN}_storeAccountID`)) ==
        listing_details?.account_id
    ) {
      setSellerNegotiateSidePan(true);
    }
  };

  return (
    seller_negotiations_list &&
    seller_negotiations_list?.length > 0 && (
      <>
        {' '}
        <div
          className="w-full"
          style={get_dynamic_styles_with_default_value(
            styles?.main_styles,
            CardMainDefaultStyles
          )}
        >
          {' '}
          <div
            className="flex flex-col gap-4"
            style={get_dynamic_styles_with_default_value(
              styles?.box_styles,
              CardBoxDefaultStyles
            )}
          >
            <div className="w-full flex items-center justify-between gp-2 flex-wrap">
              <h2 className="heading-h2">
                {Listing_text.product.real_time_offers}
              </h2>

              {/* buttons */}
              <div className=" flex items-center justify-end gap-2">
                <button
                  className="btn-primary-outline"
                  onClick={(e) => handleSidepanOpen(e)}
                >
                  {Listing_text.product.view_all_offers}
                </button>
                <button
                  className="btn-primary-outline"
                  onClick={() => {
                    dispatch(
                      sellerNegotiationsList({
                        listing_id: listing_details?.id,
                        params: {
                          account_id: listing_details?.account?.id,
                          grouped: true,
                        },
                      })
                    );
                  }}
                >
                  {isFetching ? AllIcons.loading_icon : AllIcons.refresh_icon}
                </button>
              </div>
            </div>
            {/* list */}
            {seller_negotiations_list?.map((item, index) => {
              return (
                <>
                  <div className="   w-full  min-h-20 flex   justify-between items-center    ">
                    <div className="flex items-center justify-start gap-3 ">
                      {item?.user?.profile_pic ? (
                        <img
                          src={getThumbnailImage(item?.user?.profile_pic)}
                          alt={account?.name}
                          className=" h-12 w-12 rounded-full overflow-hidden relative object-cover  object-left-top   "
                        />
                      ) : (
                        user_icon
                      )}
                      <div className="flex flex-col gap-0.5">
                        <p className="description-paragraph text-primary">
                          {item?.user?.first_name} buyer made an offer
                        </p>
                        <p className="description-caption">
                          {changeDateFormat(
                            item?.created_at,
                            'hh:mm DD-MM-YYYY'
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        {/* Seller Sidepan   step */}
        <SidePan
          id={`negotiate-1st-step-sidepan-seller`}
          sidePanOpen={sellerrNegotiateSidePan}
          setSidePanOpen={setSellerNegotiateSidePan}
          modal_section_styles={
            'fixed  ltr:right-0 rtl:left-0 bottom-0 lg:top-0 lg:my-auto   z-[60]  block     rounded-t-[40px]   lg:rounded-card transform bg-white rounded shadow-lg overflow-auto  max-w-full md:max-w-xl w-full  max-h-[80VH] md:max-h-full  lg:scrollbar-thin   lg:scrollbar-track-transparent  lg:scrollbar-thumb-transparent '
          }
          modal_inside_section_styles="w-full h-full"
          modal_width={'max-w-xl w-full'}
        >
          <SellerNegotiateList />
        </SidePan>
      </>
    )
  );
};

export default SellerNegotiatePart;
