import React from 'react';
import Link from 'next/link';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useRouter } from 'next/router';

const NavItem = ({ nav, styles }) => {
  const router = useRouter();
  const isActive = router?.pathname === nav?.href;

  console.log(isActive);
  return (
    <Link href={nav?.href ?? '#'}>
      <a
        className="relative"
        style={{
          display: styles?.nav_item?.display,
          ...get_dynamic_styles(styles?.nav_item),
        }}
      >
        <img
          style={get_dynamic_styles(styles?.nav_item_image)}
          src={nav?.image}
          alt=""
        />
        <p style={get_dynamic_styles(styles?.nav_item_title)}>{nav?.title}</p>

        {/* active style */}
        {isActive && <div style={get_dynamic_styles(styles?.active_style)} />}
      </a>
    </Link>
  );
};

export default NavItem;
