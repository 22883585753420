import React, { memo } from 'react';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { CardMainDefaultStyles } from '../../constant/default_styles/ProductDetailsStyles';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const Heading2Block = ({ data, styles }) => {
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        <h2 style={get_dynamic_styles(styles?.text_styles)}>
          {get_data_by_current_language({
            data_list: data?.translated_data,
            key_name: 'title',
            default_data: data?.title,
          })}
        </h2>
      </div>
    </div>
  );
};

export default Heading2Block;
