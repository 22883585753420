import React, { useState } from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { cn } from '../../utils/classNames';
import { dynamic_text_convert } from '../../utils/dynamicTextConvert';

const Accordion = ({ data, styles }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleButton = (index) => {
    // @ts-ignore
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        style={{
          display: styles?.box_styles?.display,
          gridTemplateColumns: dynamic_text_convert({
            main_text: styles?.box_styles?.columns_box_percentage,
            check_by: '-1%',
            value: '',
          }),
          ...get_dynamic_styles(styles?.box_styles),
        }}
      >
        {data?.list?.map((item, index) => {
          const item_style =
            index === openIndex
              ? styles?.open_card_styles
              : styles?.close_card_styles;
          return (
            <div
              key={index}
              style={{
                display: item_style?.display,
                gridTemplateColumns: dynamic_text_convert({
                  main_text: item_style?.columns_box_percentage,
                  check_by: '-1%',
                  value: '',
                }),
                ...get_dynamic_styles(item_style),
              }}
            >
              <button
                className="flex  items-center gap-6"
                onClick={() => toggleButton(index)}
                style={get_dynamic_styles(styles?.title_button_styles)}
              >
                {openIndex === index ? (
                  <img
                    className="w-auto object-cover  "
                    style={get_dynamic_styles(styles?.icon_styles)}
                    src={data?.close_button_icon}
                    alt="image"
                  />
                ) : (
                  <img
                    className="w-auto object-cover  "
                    style={get_dynamic_styles(styles?.icon_styles)}
                    src={data?.open_button_icon}
                    alt="image"
                  />
                )}

                {item.title}
              </button>

              {openIndex === index && (
                <div style={get_dynamic_styles(styles?.description_styles)}>
                  {item.description}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Accordion;
